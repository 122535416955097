export default $componet => ([
  {
    text: '021-88857483',
    desc: $componet.$t('components.website.footer.contacts.1'),
    href: 'tel://02188857483',
  },
  {
    text: '021-22369444',
    desc: $componet.$t('components.website.footer.contacts.2'),
    href: 'tel://02122369444',
  },
  {
    text: '0937-7883535',
    desc: $componet.$t('components.website.footer.contacts.3'),
    href: 'whatsapp://send?abid=989377883535',
  },
])
