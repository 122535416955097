<template>
  <v-card
    :dark="theme.website.cards.dark"
    :light="theme.website.cards.dark"
    :color="theme.website.cards.color"
    class="fill-width"
  >
    <v-card-text>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-space-between">
              <span><v-icon small class="me-1">mdi-globe-model</v-icon>{{ $t('components.website.footer.social') }}</span>
              <span class="me-2">
                <v-tooltip
                  v-for="(link, index) in socialLinks"
                  :key="`footer-link-${index}`"
                  top
                >
                  <template v-slot:activator="{on}">
                    <v-btn
                      icon
                      :href="link.href"
                      target="__blank"
                      v-on="on"
                    >
                      <v-icon>{{ link.icon }}</v-icon>
                    </v-btn>
                  </template>
                  {{ link.title }}
                </v-tooltip>
              </span>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <span><v-icon small class="me-1">mdi-phone</v-icon>{{ $t('components.website.footer.contact') }}</span>
              <div class="d-flex flex-column align-end">
                <span
                  v-for="(contact, index) in contactNumbers"
                  :key="`contact-${index}`"
                  class="d-flex flex-row align-center"
                >
                  <span class="me-1">{{ contact.desc }}</span>
                  <v-btn text tile :href="contact.href">{{ contact.text }}</v-btn>
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <div class="d-flex flex-row justify-space-between">
        <span>
          <v-icon small class="me-1">mdi-pin</v-icon>
          {{ $t('components.website.footer.location') }} - {{ $t('components.website.footer.contacts.1') }}
        </span>
        <span class="me-4" style="text-align: left;"><strong>{{ $t('components.website.footer.addressJordan') }}</strong></span>
      </div>
      <location-input
        :init-zoom="16"
        :value="locationJordan"
        :extra-locations="extraLocations"
        hide-picker
        dont-pick
      />
    </v-card-text>
    <v-card-text>
      <div class="d-flex flex-row justify-space-between">
        <span>
          <v-icon small class="me-1">mdi-pin</v-icon>
          {{ $t('components.website.footer.location') }} - {{ $t('components.website.footer.contacts.2') }}
        </span>
        <span class="me-4" style="text-align: left;"><strong>{{ $t('components.website.footer.addressSaadat') }}</strong></span>
      </div>
      <location-input
        :init-zoom="16"
        :value="locationSaadat"
        :extra-locations="extraLocations"
        hide-picker
        dont-pick
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import Socials from '../Footer/socials'
  import Contacts from '../Footer/contacts'
  import LocationInput from '@peynman/press-vue-admin/components/Inputs/LocationInput/LocationInput.vue'

  export default {
    name: 'ContactUsDetails',
    components: {
      LocationInput,
    },
    mixins: [
      Themeable,
    ],
    computed: {
      socialLinks () {
        return Socials(this)
      },
      contactNumbers () {
        return Contacts(this)
      },
      locationJordan () {
        return {
          lat: 35.75999278609336,
          lng: 51.41857859823647,
        }
      },
      locationSaadat () {
        return {
          lat: 35.776078262538405,
          lng: 51.352347999672304,
        }
      },
      extraLocations () {
        return [
          // {
          //   lat: 35.75999278609336,
          //   lng: 51.41857859823647,
          //   icon: icon({
          //     iconRetinaUrl: require('../../../assets/logo-big-white.png'),
          //     iconUrl: require('../../../assets/logo-big-white.png'),
          //     shadowUrl: null,
          //     iconSize: [38, 38],
          //   }),
          // },
        ]
      },
    },
  }
</script>
